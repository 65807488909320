(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			ViewDemo: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var filterSelector = '.insight-view-demo';
					var itemSelector = '.insight-view-demo-item';

					var $container = jQuery( '.insight-view-demo .insight-items' ).isotope( {
						itemSelector: itemSelector,
						percentPosition: true,
						masonry: {
							isFitWidth: true,
							columnWidth: '.grid-sizer'
						}
					} );

					var currentFilter = '*';
					var filterAtribute = 'class';

					setTimeout( function() {
						changeFilter();
					}, 100 );

					function changeFilter( selector ) {
						$container.isotope( {
							filter: selector
						} );
					}

					// Filter
					jQuery( filterSelector + ' .insight-filter a' ).click( function() {
						jQuery( filterSelector + ' .insight-filter a' ).removeClass( 'active' );
						jQuery( this ).addClass( 'active' );
						var filter = jQuery( this ).attr( 'data-filter' );
						currentFilter = filter;

						var selector = itemSelector;
						selector += (
							currentFilter != '*'
						) ? '[' + filterAtribute + '~="' + currentFilter + '"]' : '';
						changeFilter( selector );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
