(
	function( insight, $ ) {
		function insightOnReady() {

			if ( typeof insight.SearchBox !== 'undefined' ) {
				insight.SearchBox.init();
			}

			if ( typeof insight.RowInner !== 'undefined' ) {
				insight.RowInner.init();
			}

			if ( typeof insight.HeaderSticky !== 'undefined' ) {
				insight.HeaderSticky.init();
			}

			if ( typeof insight.SearchBoxMobile !== 'undefined' ) {
				insight.SearchBoxMobile.init();
			}

			if ( typeof insight.MiniCart !== 'undefined' ) {
				insight.MiniCart.init();
			}

			if ( typeof insight.OverlayMenu !== 'undefined' ) {
				insight.OverlayMenu.init();
			}

			if ( typeof insight.MobileMenu !== 'undefined' ) {
				insight.MobileMenu.init();
			}

			if ( typeof insight.LightboxVideo !== 'undefined' ) {
				insight.LightboxVideo.init();
			}

			if ( typeof insight.BlogMasonry !== 'undefined' ) {
				insight.BlogMasonry.init();
			}

			if ( typeof insight.BlogMetro !== 'undefined' ) {
				insight.BlogMetro.init();
			}

			if ( typeof insight.ProgressBar !== 'undefined' ) {
				insight.ProgressBar.init();
			}

			if ( typeof insight.Counter !== 'undefined' ) {
				insight.Counter.init();
			}

			if ( typeof insight.Carousel !== 'undefined' ) {
				insight.Carousel.init();
			}

			if ( typeof insight.Countdown !== 'undefined' ) {
				insight.Countdown.init();
			}

			if ( typeof insight.Piechart !== 'undefined' ) {
				insight.Piechart.init();
			}

			if ( typeof insight.Accordion !== 'undefined' ) {
				insight.Accordion.init();
			}

			if ( typeof insight.Tabs !== 'undefined' ) {
				insight.Tabs.init();
			}

			if ( typeof insight.Woo !== 'undefined' ) {
				insight.Woo.init();
			}

			if ( typeof insight.Separator !== 'undefined' ) {
				insight.Separator.init();
			}

			if ( typeof insight.LightboxGallery !== 'undefined' ) {
				insight.LightboxGallery.init();
			}

			if ( typeof insight.Page404 !== 'undefined' ) {
				insight.Page404.init();
			}

			if ( typeof insight.ScrollUp !== 'undefined' ) {
				insight.ScrollUp.init();
			}

			if ( typeof insight.HeaderSide !== 'undefined' ) {
				insight.HeaderSide.init();
			}

			if ( typeof insight.Gmaps !== 'undefined' ) {
				insight.Gmaps.init();
			}

			if ( typeof insight.PortfolioFilter !== 'undefined' ) {
				insight.PortfolioFilter.init();
			}

			if ( typeof insight.ViewDemo !== 'undefined' ) {
				insight.ViewDemo.init();
			}

			if ( typeof insight.Animate !== 'undefined' ) {
				insight.Animate.init();
			}
		}

		$( document ).ready( function() {
			insightOnReady();
		} );
	}.apply( this, [window.insight, jQuery] )
);
