(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Woo: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.woo-single-images, .woo-single-summary' ).stick_in_parent();
					jQuery( 'body.single-product .woo-single-images' ).lightGallery( {
						selector: 'a',
						thumbnail: true,
						animateThumb: false,
						showThumbByDefault: false
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
