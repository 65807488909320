(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Piechart: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.insight-pie-chart .pie-chart' ).inViewport( function( px ) {
						if ( px ) {
							var style = jQuery( this ).data('style');
							var barColor = jQuery( this ).data( 'bar-color' );

							if(style == 'grad') {
								var barColorEnd = jQuery( this ).data( 'bar-color-end' );
								jQuery( this ).easyPieChart( {
									barColor: function( percent ) {
										var ctx = this.renderer.getCtx();
										var canvas = this.renderer.getCanvas();
										var gradient = ctx.createLinearGradient( 0, 0, canvas.width, 0 );
										gradient.addColorStop( 0, barColor );
										gradient.addColorStop( 1, barColorEnd );
										return gradient;
									},
									scaleColor: false,
									lineWidth: 6,
									lineCap: 'circle',
									size: 180,
								} );
							} else {
								jQuery( this ).easyPieChart( {
									barColor: barColor,
									scaleColor: false,
									lineWidth: 6,
									lineCap: 'circle',
									size: 180,
								} );
							}

						}
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
